<div class="m13-main-container"
     [ngStyle]="{'background-image':  'url(&quot;' + (isDesktopMode()? corporate.homePageSettings.pictureDesktopUrl : corporate.homePageSettings?.pictureMobileUrl) + '&quot;)' }">


    <div class="text-container">
    <img *ngIf="!property.homePageWithoutLogo" class="m13-logo" [src]=getLogo()>

    <div *ngIf="!property.homePageWithoutTitle" [ngStyle]="{ 'color': corporate.homePageSettings?.fontColor }" class="m13-first-title y3-High y3-bold">{{corporate.homePageSettings?.agencyTitle ? corporate.homePageSettings?.agencyTitle : corporate.name}}</div>

    <!--<div class="y3-Large" [ngStyle]="{ 'color': corporate.homePageSettings?.fontColor ? corporate.homePageSettings?.fontColor: 'black' }">{{corporate.address}}</div>
      <div class="y3-Large" [ngStyle]="{ 'color': corporate.homePageSettings?.fontColor ? corporate.homePageSettings?.fontColor: 'black' }">{{corporate.zipCode}} {{corporate.city}}</div>-->

    <div *ngIf="isDesktopMode() && corporate.homePageSettings?.agencyEmail" class="contact" >
      <div class="mail-position">
        <!-- <img class="mail-width" src="/assets/icon/mail-white.svg"> -->
        <mat-icon [ngStyle]="{ 'color': corporate.homePageSettings?.fontColor ? corporate.homePageSettings?.fontColor: 'black' }" class="mail-width">mail_outline</mat-icon>
        <div class="contact-display">

          <a [ngStyle]="{ 'color': corporate.homePageSettings?.fontColor }" href="mailto:{{corporate.homePageSettings?.agencyEmail}}">{{corporate.homePageSettings?.agencyEmail}}</a>
        </div>
      </div>
      <div class="phone-position" *ngIf="corporate.homePageSettings?.agencyPhone != null">
        <mat-icon class="phone-width" [ngStyle]="{ 'color': corporate.homePageSettings?.fontColor }">phone_in_talk</mat-icon>
        <!-- <img [ngStyle]="{ 'color': homeSettings?.fontColor }" class="phone-width" src="/assets/icon/phone-call.svg"> -->

        <div  class="contact-display">
          <a [ngStyle]="{ 'color': corporate.homePageSettings?.fontColor }" href="tel:{{corporate.homePageSettings?.agencyPhone}}">{{corporate.homePageSettings?.agencyPhone}}</a>
        </div>
      </div>
    </div>
    <div *ngIf="!isDesktopMode() && corporate.homePageSettings?.agencyPhone != null || corporate.homePageSettings?.agencyEmail != null" class="contact" >
      <div class="phone-position" *ngIf="corporate.homePageSettings?.agencyPhone != null">
        <a  href="tel:{{corporate.homePageSettings?.agencyPhone}}">
                        <span [ngStyle]="{ 'color': corporate.homePageSettings?.fontColor ? corporate.homePageSettings?.fontColor : 'black',
                        'border-color' : corporate.homePageSettings?.fontColor ? corporate.homePageSettings?.fontColor : 'black' }" class="inner-circle">
                            <mat-icon  class="phone-width" [ngStyle]="{ 'color': corporate.homePageSettings?.fontColor }">phone_in_talk</mat-icon>
                        </span>
          <!-- <img class="phone-width" src="/assets/icon/white-phone.svg"> -->
        </a>
      </div>
      <div class="mail-position" *ngIf="corporate.homePageSettings?.agencyEmail">
        <a href="mailto:{{corporate.homePageSettings?.agencyEmail}}">
                        <span [ngStyle]="{ 'color': corporate.homePageSettings?.fontColor ? corporate.homePageSettings?.fontColor : 'black',
                        'border-color' : corporate.homePageSettings?.fontColor ? corporate.homePageSettings?.fontColor : 'black' }" class="inner-circle">
                            <mat-icon  class="phone-width" [ngStyle]="{ 'color': corporate.homePageSettings?.fontColor }">mail_outline</mat-icon>

                        </span>
        </a>
      </div>
      <!-- <div class="mail-position">
          <a href="mailto:{{propertyDetails?.host?.email}}">
              <img class="mail-width" src="/assets/icon/white-mail.svg">
          </a>
      </div> -->

    </div>
    <hr *ngIf="!property.homePageWithoutTitle" [ngStyle]="{ 'border': '2px solid' + corporate.homePageSettings?.fontColor ? corporate.homePageSettings?.fontColor : 'black' }">
    <div *ngIf="!property.homePageWithoutTitle" [ngStyle]="{ 'color': corporate.homePageSettings?.fontColor ? corporate.homePageSettings?.fontColor : 'black' }" class="property-name">
      {{property?.bookletTitle ? property?.bookletTitle : property?.title  }}
    </div>
    <div *ngIf="!property.homePageWithoutTitle" [ngStyle]="{ 'color': corporate.homePageSettings?.fontColor }" class="adresse y3-X-small">
      {{property?.fullAddress}}
    </div>
    <!--<div *ngIf="!property.homePageWithoutTitle && !property.homePageWithoutLogo">-->

      <div class="m13-mobile-bottom-button">
        <div class="y3-flex-row y3-full-width y3-flex-align-center" style="margin: auto ">
          <div class="y3-full-width">
          <app-button (debounceClick)="goToBooklet()" [type]="'primary'"
                  text="{{'welcome-page.Access-to-booklet' | translate}} "></app-button>
          </div>
        </div>
      </div>
    <!--</div>-->

  </div>



  </div>

<!--<div *ngIf="property.homePageWithoutTitle && property.homePageWithoutLogo"  style="position: fixed; bottom: 32px; width: 100%">
  <div class="y3-flex-row y3-flex-justify-content-center y3-full-width">
    <app-button (debounceClick)="goToBooklet()" [type]="'primary'"
                text="{{'welcome-page.Access-to-booklet' | translate}} "></app-button>
  </div>
</div>-->
